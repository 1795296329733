import LegalLayout from "../../../components/legal/LegalLayout"
import React from "react"
import source from "raw-loader!../../../constants/legal/abuse/index.md"

export default function AbusePage() {
  return (
    <LegalLayout
      description="It is not okay to use Ready Five for these restricted purposes."
      heading="Ready Five Restricted Use Policy"
      source={source}
      title="Use Restrictions"
    />
  )
}
